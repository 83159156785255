import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../_shared/Layout'

export default class Post extends Component {
  render() {
    const post = this.props.data.markdownRemark;
    return (
      <Layout>
        <article>
          <Helmet
            title={post.frontmatter.title}
            meta={[
              { name: 'author', content: 'Jakša Bašić' },
              { name: 'twitter:site', content: "jaksabasic.com" },
              { name: 'twitter:creator', content: 'Jakša Bašić' },
              { name: 'twitter:title', content: post.frontmatter.title },
              { name: 'twitter:image', content: post.frontmatter.cover.publicURL },
              { property: 'og:title', content: post.frontmatter.title },
              { property: 'og:site_name', content: "jaksabasic.com" },
              { property: 'og:type', content: "website" },
              { property: 'og:url', content: `https://jaksabasic.com${post.fields.slug}` },
              { property: 'og:description', content: post.excerpt },
              { property: 'og:image', content: post.frontmatter.cover.publicURL },
              { name: 'viewport', content: 'width=device-width, initial-scale=1.0' }
            ]}
          />
          <div className="post-preview"
            style={{
              background: `url(${post.frontmatter.cover.publicURL}) no-repeat center center`,
              backgroundSize: 'cover'
            }}>
          </div>
          <figure>
            <figcaption>Remote work setup, <a href="https://pixabay.com/en/home-office-workstation-office-336377">source</a></figcaption>
          </figure>
          <main className="text-container">
            <header className="post__header">
              <h1>{post.frontmatter.title}</h1>
              <time>{post.frontmatter.date}</time>
            </header>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
            <div className="share">
              <a className="twitter-share-button" href={`https://twitter.com/intent/tweet?text=%E2%80%9C${post.frontmatter.title}%E2%80%9C%20@jaksabasic%20 https://jaksabasic.netlify.com/${post.fields.slug}`} target="_blank" rel="noopener noreferrer">Tweet</a>
              <a className="facebook-share-button" href={`https://www.facebook.com/sharer/sharer.php?u=https://jaksabasic.netlify.com/${post.fields.slug}`} target="_blank" rel="noopener noreferrer">Facebook</a>
            </div>
          </main>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover {
          publicURL
        } 
      }
    }
  }
`
